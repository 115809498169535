var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"text-align":"center","height":"100vh","width":"100vw"}},[_c('van-overlay',{attrs:{"show":_vm.loading},on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center","height":"100%"}},[_c('van-loading',{attrs:{"size":"60","type":"spinner"}})],1)]),_c('div',{staticClass:"flex-v",style:({
      width: '100%',
      height: '100%',
      'background-image': 'url(' + _vm.picUrl + ')',
      'background-repeat': 'no-repeat',
      'background-size': '100% 100%',
      '-moz-background-size': '100% 100%',
    })},[_c('div',{staticStyle:{"padding-left":"10%","padding-right":"10%","padding-top":"8%"}},[_c('div',{staticStyle:{"padding-bottom":"6px"}},[_c('van-row',[_c('van-col',{attrs:{"span":"1"}}),_c('van-col',{attrs:{"span":"22"}},[_c('div',{style:({
                width: '100%',
                'background-image': 'url(' + _vm.cardBg + ')',
                'background-repeat': 'no-repeat',
                'background-size': '100% 100%',
                '-moz-background-size': '100% 100%',
              })},[_c('div',{staticStyle:{"height":"300px","padding-top":"30px","display":"flex","align-items":"center","flex-direction":"column"}},[_c('van-image',{staticStyle:{"border":"2px solid white","border-radius":"4px"},attrs:{"height":"160","width":"80%","src":_vm.has[_vm.choose].ids.length
                      ? _vm.buildingList[_vm.choose].pic
                      : _vm.buildingList[_vm.choose].picShadow},on:{"click":function($event){_vm.has[_vm.choose].ids.length ? (_vm.showDetail = true) : null}}}),_c('div',{staticStyle:{"color":"white","line-height":"30px","font-weight":"600"}},[_vm._v(" "+_vm._s(_vm.buildingList[_vm.choose].title)+" ")]),_c('van-image',{staticStyle:{"margin-top":"2px"},attrs:{"width":"30%","src":_vm.share},on:{"click":function($event){_vm.has[_vm.choose].ids.length > 1 ? _vm.go() : _vm.noticeShare()}}})],1)])]),_c('van-col',{attrs:{"span":"1"}})],1)],1),_c('van-swipe',{attrs:{"loop":false,"width":70,"show-indicators":false}},_vm._l((_vm.buildingList),function(item,i){return _c('van-swipe-item',{key:i},[_c('div',{staticStyle:{"padding-right":"14px","padding-top":"10px"}},[(_vm.has[i].ids.length)?_c('van-badge',{staticStyle:{"width":"100%"},attrs:{"content":_vm.has[i].ids.length,"color":"#1989fa"}},[_c('van-image',{style:({
                  border: i == _vm.choose ? '2px solid white' : '2px solid #2D84D3',
                  'border-radius': '4px',
                }),attrs:{"height":"50","src":item.picMini},on:{"click":function($event){_vm.choose = i}}}),_c('div',{staticStyle:{"font-size":"12px","color":"white"}},[_vm._v(_vm._s(item.label1))]),_c('div',{staticStyle:{"font-size":"12px","color":"white"}},[_vm._v(_vm._s(item.label2))])],1):_c('div',{staticStyle:{"width":"100%"}},[_c('van-image',{style:({
                  border: i == _vm.choose ? '2px solid white' : '2px solid #2D84D3',
                  'border-radius': '4px',
                }),attrs:{"height":"50","src":item.picMiniShadow},on:{"click":function($event){_vm.choose = i}}}),_c('div',{staticStyle:{"font-size":"12px","color":"white"}},[_vm._v(_vm._s(item.label1))]),_c('div',{staticStyle:{"font-size":"12px","color":"white"}},[_vm._v(_vm._s(item.label2))])],1)],1)])}),1),_c('div',{staticStyle:{"line-height":"40px","color":"white","font-size":"13px","font-weight":"500"}},[_vm._v(" 今日剩余机会："+_vm._s(_vm.chance)+"次 ")]),_c('van-row',[_c('van-col',{attrs:{"span":"12"}},[_c('van-image',{attrs:{"width":"120","src":_vm.draw},on:{"click":_vm.drawFunction}})],1),_c('van-col',{attrs:{"span":"12"}},[_c('van-image',{attrs:{"width":"120","src":_vm.link},on:{"click":_vm.goLink}})],1)],1)],1)]),_c('van-dialog',{attrs:{"title":_vm.buildingList[_vm.choose].title,"confirmButtonText":"收下卡片","theme":"round-button"},model:{value:(_vm.showDetail),callback:function ($$v) {_vm.showDetail=$$v},expression:"showDetail"}},[_c('div',{staticStyle:{"padding":"4px 10px","height":"360px","overflow":"auto"}},[_c('van-image',{attrs:{"width":"100%","src":_vm.buildingList[_vm.choose].pic},on:{"click":function($event){return _vm.showPic([_vm.buildingList[_vm.choose].pic])}}}),_c('div',{staticStyle:{"text-align":"start","font-size":"12px"},domProps:{"innerHTML":_vm._s(_vm.buildingList[_vm.choose].text)}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }